import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { loadingOff } from "../../utils/common";
import routes from "../routes";

import Base from "../../assets/images/base.png";
import Logo from "../../assets/images/logo_secundario.png";
import HealthBanner from "../../assets/images/health-banner.jpg";

export const AgeGate = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleYes = () => {
        navigate(routes.phoneForm);
    };

    const handleNo = () => {
        navigate(routes.sorry);
    };

    useEffect(() => {
        loadingOff();
    }, []);

    return (
        <section className="sorry-section">
            <div className="row">
                <img id="logo_secundario" src={Logo} alt="logo" />
            </div>

            <div className="row">
                <div className="container-2 col-12">
                    <img
                        className="img-fluid health-banner"
                        src={HealthBanner}
                        alt="health banner"
                    />
                    <div className="row">
                        <div className="col-12 text-center">
                            <img src={Base} alt="base" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <label
                                htmlFor="cellphone"
                                className="titulo_principal"
                            >
                                {t("page.ageGate.title")}
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 text-center">
                            <button
                                type="submit"
                                id="btn_yes"
                                className="btn btn_secundario"
                                onClick={handleYes}
                            >
                                {t("page.ageGate.yesBtn")}
                            </button>
                        </div>
                        <div className="col-6 text-center">
                            <button
                                type="submit"
                                id="btn_no"
                                className="btn btn_principal"
                                onClick={handleNo}
                            >
                                {t("page.ageGate.noBtn")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
