const routes = {
    home: "/",
    ageGate: "/step0",
    phoneForm: '/step1',
    registerForm: '/step3',
    otpGate: "/otpGate",
    sorry: "/sorry",
    success: '/success'
};

export default routes;
