import { createContext, useContext, useState } from "react";

const StateContext = createContext({
    userPhone: null,
    retailerCode: null,
    setUserPhone: () => {},
    setRetailerCode: () => {},
});

export const ContextProvider = ({ children }) => {
    const [userPhone, _setUserPhone] = useState(
        sessionStorage.getItem("cmrUserPhone")
    );
    const [retailerCode, _setRetailerCode] = useState(
        sessionStorage.getItem("cmrRetailerCode")
    );

    const setUserPhone = (phone) => {
        _setUserPhone(phone);
        sessionStorage.setItem("cmrUserPhone", phone);
    };

    const setRetailerCode = (phone) => {
        _setRetailerCode(phone);
        sessionStorage.setItem("cmrRetailerCode", phone);
    };

    return (
        <StateContext.Provider
            value={{
                userPhone,
                retailerCode,
                setUserPhone,
                setRetailerCode,
            }}
        >
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);
