import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import routes from "../routes";

import SparrkupLogo from "../../assets/images/sparkup.png";
import SorryImg from "../../assets/images/icon-sorry.png";
import HealthBanner from "../../assets/images/health-banner.jpg";

export const Sorry = () => {
    const { t } = useTranslation();

    return (
        <section className="sorry-section">
            <img
                className="img-fluid health-banner"
                src={HealthBanner}
                alt="health banner"
            />
            <div className="text-center">
                <div className="logo">
                    <Link to={routes.home}>
                        <img src={SparrkupLogo} alt="sparrkup logo" />
                    </Link>
                </div>
            </div>
            <div className="text-center">
                <div className="icon-emoji">
                    <img src={SorryImg} alt="sorry" />
                </div>
            </div>
            <h3>{t("page.sorry.title")}</h3>
            <h4 className="title-center">{t("page.sorry.description")}</h4>
        </section>
    );
};
