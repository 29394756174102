import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../contexts/ContextProvider";
import { useEffect } from "react";

import routes from "../routes";

import SparrkupLogo from "../../assets/images/logo_principal.png";
import HealthBanner from '../../assets/images/health-banner.jpg';

export const Welcome = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams();
    const { setRetailerCode } = useStateContext();

    useEffect(() => {
        setRetailerCode(id);
    }, [])

    const handleNavigation = () => {
        navigate(routes.ageGate);
    };

    return (
        <section className="welcome-section">
            <form className="form-signin">
                <div className="row">
                    <div className="col-sm-12 msg-box"></div>
                </div>
                <div className="row">
                    <img className="img-fluid" src={HealthBanner} alt="health banner" />
                    <label id="welcome">{t("page.welcome.title")}</label>
                </div>

                <div className="text-center">
                    <div className="icon-logo">
                        <img src={SparrkupLogo} alt="sparrkup logo" />
                    </div>
                </div>
                <br />

                <br />
                <div className="row">
                    <div className="col-12 text-center">
                        <button
                            type="button"
                            id="btn_welcome"
                            className="btn btn-primary"
                            onClick={handleNavigation}
                        >
                            {t("page.welcome.button")}
                        </button>
                    </div>
                </div>
            </form>
        </section>
    );
};
