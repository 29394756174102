import { createRef, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axiosClient from "../../utils/axios-client";
import routes from "../routes";
import { loadingOff, loadingOn } from "../../utils/common";

import SparrkupLogo from "../../assets/images/logo_secundario.png";
import BaseImg from "../../assets/images/base.png";
import HealthBanner from "../../assets/images/health-banner.jpg";

export const OtpGate = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [phoneNumber] = useState(() => {
        return sessionStorage.getItem("cmrUserPhone") || "";
    });
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [timer, setTimer] = useState(50);
    const formRef = createRef();
    const smsCodeRef = useRef();

    useEffect(() => {
        requestOtpCode();
        const countdown = setInterval(() => {
            setTimer((prev) => {
                if (prev > 1) {
                    return prev - 1;
                } else {
                    clearInterval(countdown);
                    setIsResendDisabled(false);
                    return 0;
                }
            });
        }, 1000);
        return () => clearInterval(countdown);
    }, []);

    const requestOtpCode = () => {
        if (phoneNumber.length > 0) {
            const areaCode = phoneNumber.charAt(0);
            const phoneNum = phoneNumber.slice(1);

            const payload = {
                area_code: areaCode,
                phone_number: phoneNum,
            };

            axiosClient
                .post("/otp", payload)
                .then((response) => {
                    if (response.status === 200) {
                        console.log("OTP request successful.");
                        setIsResendDisabled(true);
                        setTimer(50);
                        startTimer();
                    }
                })
                .catch((err) => {
                    console.error("Error requesting OTP:", err);
                    alert("An error occurred while requesting the OTP.");
                });

        } else {
            console.warn("Number phone is empty");
        }
    };

    const startTimer = () => {
        const countdown = setInterval(() => {
            setTimer((prev) => {
                if (prev > 1) {
                    return prev - 1;
                } else {
                    clearInterval(countdown);
                    setIsResendDisabled(false);
                    return 0;
                }
            });
        }, 1000);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (phoneNumber.length > 0) {
            const areaCode = phoneNumber.charAt(0);
            const phoneNum = phoneNumber.slice(1);
            const smsCode = smsCodeRef.current.value;

            const payload = {
                area_code: areaCode,
                phone_number: phoneNum,
                sms_code: smsCode,
            };

            axiosClient
                .post("/otp/validate", payload)
                .then((response) => {
                    if (response.status === 200) {
                        // alert("OTP validation successful.");
                        navigate(routes.registerForm);
                    }
                })
                .catch((err) => {
                    console.error("Error validating OTP:", err);
                    alert(t("page.otpForm.invalid_code"));
                });
        } else {
            console.warn("Number phone is empty");
        }
    };

    const handleCancel = () => {
        navigate(routes.phoneForm);
    };

    return (
        <form className="form-signin" onSubmit={handleSubmit} ref={formRef}>
            <div className="row">
                <img
                    id="logo_secundario"
                    src={SparrkupLogo}
                    alt="sparrkup logo"
                />
            </div>

            <div className="container-2 col-12">
                <img
                    className="img-fluid health-banner"
                    src={HealthBanner}
                    alt="health banner"
                />
                <div className="row">
                    <div className="col-12 text-center">
                        <img src={BaseImg} alt="base" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 text-center">
                        <label
                            htmlFor="fld_cellphone_number"
                            className="titulo_principal"
                        >
                            {t("page.otpForm.title")}:
                        </label>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control fld_phone_number_otp mb-3"
                            id="fld_phone_number_otp"
                            name="fld_phone_number_otp"
                            value={phoneNumber}
                            readOnly
                            style={{
                                border: 'none',
                                fontWeight: 'bold',
                                color: 'black',
                                pointerEvents: 'none',
                                outline: 'none',
                                background: 'none',
                                textAlign: 'center'
                            }}
                        />
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-sm-10">
                        <label
                            htmlFor="region"
                            className="col-form-label label-form"
                        >
                            {t("page.otpForm.smsCode")} *
                        </label>
                    </div>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="fld_sms_code_otp"
                            name="fld_sms_code_otp"
                            ref={smsCodeRef}
                            required
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 text-center">
                        <button
                            type="submit"
                            id="buttonSubmit"
                            className="btn btn_principal submit"
                        >
                            {t("page.otpForm.button")}
                        </button>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-12 text-center">
                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                            {t("page.otpForm.cancelButton")}
                        </button>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-12 text-center">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={requestOtpCode}
                            disabled={isResendDisabled}
                        >
                            {isResendDisabled ? `${t("page.otpForm.resend")} (${timer}s)` : t("page.otpForm.resend")}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};
